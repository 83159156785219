import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { FaUserPlus } from "react-icons/fa";
import "./style.css";
import { FaFileContract } from "react-icons/fa";
import checkTokenExpiration from "../../affiliateLib/destroyAuth/DestroyAuth";
const AffiliateSidebar = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  //Check the Token is Expire Or Not
  useEffect(() => {
    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 1000);
    return () => clearInterval(interval);
  }, [navigate]);
  return (
    <>
      <aside
        className="sidenav bg-white navbar navbar-vertical  border-0 border-radius-xl my-3 fixed-start ms-4 "
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <a className="navbar-brand m-0 text-center" target="_blank">
          <div className="side-nav-top-logo ">
                <img src="/newLogoGuardx.png" alt="" className="" />
            
                <b>{language === "hindi" ? "  Welcome" : "स्वागत"}</b>
              </div>
            {/* {roleType === 1 || roleType === 2 ? (
              <div className="guard_logo_div ">
                <img src="/GuardX.png" alt="" className="" />
                &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>{language === "hindi" ? "  Welcome" : "स्वागत"}</b>
              </div>
            ) : (
              <div className="society_logo_div">
                <img
                  src={`/${societyDetails?.societyLogo?.replace(
                    "public/",
                    ""
                  )}`}
                  alt=""
                  className="society-logo-on-nav"
                />

                <b>{language === "hindi" ? "  Welcome" : "स्वागत"}</b>
              </div>
            )} */}
            <span className="ms-1 font-weight-bold"></span>
          </a>
        </div>
        <hr className="horizontal dark mt-0" />
        <div className="affiliate-side-nav-main-div">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link "
                to="/affiliate/affiliateDashboard"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <MdDashboard className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "hindi" ? " Dashboard" : " डैशबोर्ड"}
                  </span>
                </span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link"
                to="/affiliate/viewAffiliateClient"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <FaUserPlus className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "hindi" ? "Client" : " क्लाइंट"}
                  </span>
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link"
                to="/affiliate/viewAffiliateContracts"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <FaFileContract className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "english" ? "अनुबंध" : "Contracts"}
                  </span>
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default AffiliateSidebar;

import React, { useContext } from "react";
import AdminNavbar from "../AdminPannel/AdminNavbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { PORT } from "../Api/api";
import axios from "axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useState } from "react";
import Layout from "../lib/Layout";
import AddBackbtn from "../lib/AddBackbtn";
import { LanguageContext } from "../lib/LanguageContext";
import { Tooltip } from "@mui/material";
const EditRole = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [selectedRoleType, setSelectedRoleType] = useState("");
  const [societyLevelRole, setSocietyLevelRole] = useState();
  const [societySubAdmin, setSocietySubAdmin] = useState("guardAccess");
  const roleType = JSON.parse(localStorage.getItem("role"));
  const [roleData, setRoleData] = useState();
  const [admin, setAdmin] = useState("adminRoleType");
  const [selectedActionsSaas, setSelectedActionsSaas] = useState({});
  const [selectedActionsSociety, setSelectedActionsSociety] = useState({});
  const [selectedActionsSocietyLevel, setSelectedActionsSocietyLevel] =
    useState({});
  const [selectedActionsGuardAccess, setSelectedActionsGuardAccess] = useState(
    {}
  );
  const defaultActions = ["Module", "Create", "Read", "Edit", "Delete"];
  const [filteredModules, setFilteredModules] = useState([]);
  //getRoleDataWithId
  const getRoleDataWithId = async (id) => {
    try {
      await axios.get(`${PORT}/getEditRole/${id}`).then((res) => {
        setRoleData(res.data.getEditData);
        const data=res.data.getEditData
        console.log(data.permissions,"data--------->")
        setSocietyLevelRole()
        formik.resetForm({
          values: {
            title: data.title || "",
            desc: data.desc || "",
            permissions: data.permissions || [],
            roleType: data.roleTypeLevelSociety,
          },
        });
      });
     
      // formik.setFieldValue("permissions", fetchedData.permissions || []);

    // Trigger filtering
    // 
    } catch (error) {}
  };
  //getRoleDataWithId Lock
  useEffect(() => {
    getRoleDataWithId(id);
  }, [id]);
  // validationSchema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    desc: Yup.string().required("Description is required"),
    permissions: Yup.array()
      .of(
        Yup.object().shape({
          moduleName: Yup.string().required("Module name is required"),
          actions: Yup.array()
            .of(Yup.string())
            .required("At least one action is required"),
        })
      )
      .required("Permissions are required"),
  });
  //initialModules
  const initialModules = [
    { moduleName: "Regular Entries", actions: [] },
    { moduleName: "Guest Entries Request", actions: [] },
    { moduleName: "Type of Entries", actions: [] },
    { moduleName: "Purpose of Occasional", actions: [] },
    { moduleName: "House List", actions: [] },
    { moduleName: "Roles", actions: [] },
    { moduleName: "Society List", actions: [] },
    { moduleName: "Public access", actions: [] },
  ];
  //editRole function
  const formik = useFormik({
    initialValues: {
      title: "",
      desc: "",
      permissions: initialModules,
      roleTypeLevelSociety: societyLevelRole,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await axios.put(`${PORT}/editRole/${id}`, values).then((res) => {
          toast.success(`${res.data.msg}`);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        });
      } catch (error) {
        toast.error(`${error.response.data.msg}`);
      }
    },
  });
  //handleCheckboxChange functionlality
  const handleCheckboxChange = (moduleName, action) => {
    const updatedPermissions = formik.values.permissions.map((permission) => {
      if (permission.moduleName === moduleName) {
        if (permission.actions.includes(action)) {
          return {
            ...permission,
            actions: permission.actions.filter((a) => a !== action),
          };
        } else {
          return {
            ...permission,
            actions: [...permission.actions, action],
          };
        }
      }
      return permission;
    });
    formik.setFieldValue("permissions", updatedPermissions);
  };

  const filterPermissions = () => {
    return formik.values.permissions.filter((permission) => {
      switch (roleType) {
        case 1:
          switch (selectedRoleType) {
            case "saas":
              formik.setFieldValue("roleTypeLevelSociety", "saas");
              return (
                permission.moduleName !== "Public access" &&
                permission.moduleName !== "Regular Entries" &&
                permission.moduleName !== "Guest Entries Request" &&
                permission.moduleName !== "House List" &&
                permission.moduleName !== "Attendance" &&
                permission.moduleName !== "Announcements" &&
                permission.moduleName !== "Complaints"
              );
            case "society":
              formik.setFieldValue("roleTypeLevelSociety", "society");
              return (
                permission.moduleName !== "Society List" &&
                permission.moduleName !== "Public access" &&
                permission.moduleName !== "Subscription" &&
                permission.moduleName !== "Affiliate User"
              );
            default:
              return false;
          }
        case 2:
          switch (admin) {
            case "adminRoleType":
              return (
                permission.moduleName !== "Society List" &&
                permission.moduleName !== "Public access" &&
                permission.moduleName !== "Subscription" &&
                permission.moduleName !== "Affiliate User"
              );
            default:
              return false;
          }
        case 4:
          switch (societyLevelRole) {
            case "societyLevel":
              formik.setFieldValue("roleTypeLevelSociety", societyLevelRole);
              return (
                permission.moduleName !== "Society List" &&
                permission.moduleName !== "Public access" &&
                permission.moduleName !== "Subscription" &&
                permission.moduleName !== "Affiliate User"
              );
            case "guardAccess":
              formik.setFieldValue("roleTypeLevelSociety", societyLevelRole);
              return permission.moduleName === "Public access";
            default:
              return false;
          }
        case 5:
          switch (societySubAdmin) {
            case "guardAccess":
              return permission.moduleName === "Public access";
            default:
              return false;
          }
        default:
          if (selectedRoleType === "saas") {
            return true;
          }
          return false;
      }
    });
  };

  useEffect(() => {
    const modules = filterPermissions();
    setFilteredModules(modules);
    //saas level check
    setSelectedActionsSaas(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
    //society level check
    setSelectedActionsSociety(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
    //society level check
    setSelectedActionsSocietyLevel(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
    //guard level check
    setSelectedActionsGuardAccess(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
  }, [formik.values.permissions, roleType, selectedRoleType, societyLevelRole]);

  useEffect(() => {
    if (roleData) {
      formik.setValues({
        title: roleData.title,
        desc: roleData.desc,
        permissions: roleData.permissions,
        roleTypeLevelSociety: roleData.roleTypeLevelSociety,
      });
    }
  }, [roleData]);

  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? "Edit Roles" : "भूमिकाएँ संपादित करें "}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className=" roles_main_div">
          <form onSubmit={formik.handleSubmit} className="form_roles">
            <div>
              <label htmlFor="title" className="editLabel">
                {language === "hindi" ? "Title" : "शीर्षक  "}
                <span className="Star_color">*</span>
              </label>
              <br />
              <input
                type="text"
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Title"
                className="edit-input-role"
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="error"> {formik.errors.title}</div>
              ) : null}
            </div>
            <br />
            <div>
              <label htmlFor="desc" className="editLabel">
                {language === "hindi" ? " Description" : "विवरण   "}
                <span className="Star_color">*</span>
              </label>
              <br />
              <textarea
                id="desc"
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Description"
                className="edit-input-role"
              />
              {formik.touched.desc && formik.errors.desc ? (
                <div className="error">{formik.errors.desc}</div>
              ) : null}
            </div>
            <br />
            {roleType === 1 && (
              <div className="role-type-selection">
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />
                <Tooltip
                  title={
                    selectedRoleType === "society" &&
                    selectedActionsSociety.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "आप सास भूमिका का चयन तब तक नहीं कर सकते जब तक आप समाज की अनुमतियों को अनचेक नहीं करते।"
                        : "You cannot select the SaaS role until you uncheck the Society  permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="saas"
                      disabled={
                        selectedRoleType === "society" &&
                        selectedActionsSociety.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      checked={formik.values.roleTypeLevelSociety === "saas"}
                      onChange={() => setSelectedRoleType("saas")}
                    />
                    {language === "hindi"
                      ? " SAAS Level Role"
                      : "समाज स्तर पर भूमिका "}
                  </label>
                </Tooltip>
                <Tooltip
                  title={
                    selectedRoleType === "saas" &&
                    selectedActionsSaas.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "जब तक आप सास की अनुमति को अनचेक नहीं करेंगे, तब तक आप समाज की भूमिका नहीं देख सकते"
                        : "You cannot select the Society role until you uncheck the SaaS permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="society"
                      disabled={
                        selectedRoleType === "saas" &&
                        selectedActionsSaas.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      checked={formik.values.roleTypeLevelSociety === "society"}
                      onChange={() => setSelectedRoleType("society")}
                    />
                    {language === "hindi" ? " Society Role" : "समाज की भूमिका "}
                  </label>
                </Tooltip>
              </div>
            )}
            {roleType === 2 && (
              <div className="role-type-selection">
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    name="roleType"
                    value="adminRoleType"
                    checked={admin === "adminRoleType"}
                    onChange={() => setAdmin("adminRoleType")}
                  />
                  {language === "hindi"
                    ? "Society Level Role"
                    : " समाज स्तर की भूमिका "}
                </label>
              </div>
            )}
            {roleType === 4 && (
              <div className="role-type-selection">
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />
                <Tooltip
                  title={
                    societyLevelRole === "guardAccess" &&
                    selectedActionsGuardAccess.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "आप समाज स्तर की भूमिका का चयन तब तक नहीं कर सकते जब तक आप गार्ड एक्सेस अनुमतियों को अनचेक नहीं करते।"
                        : "You cannot select the Society Level Role until you uncheck the Guard Access permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="societyLevel"
                      checked={
                        formik.values.roleTypeLevelSociety === "societyLevel"
                      }
                      disabled={
                        societyLevelRole === "guardAccess" &&
                        selectedActionsGuardAccess.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      onChange={() => setSocietyLevelRole("societyLevel")
                        
                      }
                    />
                    {language === "hindi"
                      ? "Society Level Role"
                      : " समाज स्तर की भूमिका "}
                  </label>
                </Tooltip>
                <Tooltip
                  title={
                    societyLevelRole === "societyLevel" &&
                    selectedActionsSocietyLevel.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "आप सास भूमिका का चयन तब तक नहीं कर सकते जब तक आप समाज की अनुमतियों को अनचेक नहीं करते।"
                        : "You cannot select the Guard Access until you uncheck the Society Level   permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="guardAccess"
                      disabled={
                        societyLevelRole === "societyLevel" &&
                        selectedActionsSocietyLevel.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      checked={
                        formik.values.roleTypeLevelSociety === "guardAccess"
                      }
                      onChange={() => setSocietyLevelRole("guardAccess")}
                    />
                    {language === "hindi" ? "Guard Access" : "  गार्ड एक्सेस"}
                  </label>
                </Tooltip>
              </div>
            )}

            {roleType === 5 && (
              <div className="role-type-selection">
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />

                <label>
                  <input
                    type="radio"
                    name="roleType"
                    value="guardAccess"
                    checked={societySubAdmin === "guardAccess"}
                    onChange={() => setSocietySubAdmin("guardAccess")}
                  />
                  {language === "hindi" ? "Guard Access" : "  गार्ड एक्सेस"}
                </label>
              </div>
            )}
            {/* <label htmlFor="permissions" className="editLabel">Permissions</label> <br /><br /> */}
            <div className="main_permission">
              <table border="1">
                <thead>
                  <tr>
                    <th className="module-th-name">
                      {language === "hindi"
                        ? "Module Name"
                        : "  मोड्यूल का नाम"}
                    </th>
                    <th>
                      {language === "hindi"
                        ? " Module Actions"
                        : " मॉड्यूल क्रियाएँ"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { filteredModules.map((permission, index) => (
                    <tr key={index}>
                      <td>{permission.moduleName}</td>
                      <td>
                        <div className="module-action-div">
                          {permission.moduleName === "Public access" ||
                          permission.moduleName === "Announcements" ||
                          permission.moduleName === "Complaints" ||
                          permission.moduleName === "Attendance" ||
                          permission.moduleName === "Billing and Invoice" ? (
                            <>
                              {permission.moduleName === "Public access" && (
                                <h4 key="Public">
                                  <input
                                    type="checkbox"
                                    name={`${permission.moduleName}Public`}
                                    id={`${permission.moduleName}Public`}
                                    checked={permission.actions.includes(
                                      "public"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        permission.moduleName,
                                        "public"
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`${permission.moduleName}Public`}
                                  >
                                    Public
                                  </label>
                                </h4>
                              )}
                              {permission.moduleName === "Attendance" && (
                                <>
                                  <td
                                    // key="Attendance"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Module`}
                                      id={`${permission.moduleName}Module`}
                                      checked={permission.actions.includes(
                                        "module"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "module"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Module`}
                                    >
                                      Module
                                    </label>
                                  </td>

                                  <td
                                    key="Attendance"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Read`}
                                      id={`${permission.moduleName}Read`}
                                      checked={permission.actions.includes(
                                        "read"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "read"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Read`}
                                    >
                                      Read
                                    </label>
                                  </td>
                                </>
                              )}
                              {permission.moduleName ===
                                "Billing and Invoice" && (
                                <>
                                  <td
                                    // key="Billing and Invoice"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Module`}
                                      id={`${permission.moduleName}Module`}
                                      checked={permission.actions.includes(
                                        "module"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "module"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Module`}
                                    >
                                      Module
                                    </label>
                                  </td>

                                  <td
                                    key="Billing and Invoice"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Read`}
                                      id={`${permission.moduleName}Read`}
                                      checked={permission.actions.includes(
                                        "read"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "read"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Read`}
                                    >
                                      Read
                                    </label>
                                  </td>
                                </>
                              )}
                              {permission.moduleName === "Announcements" && (
                                <>
                                  <td
                                    key="Announcements"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Module`}
                                      id={`${permission.moduleName}Module`}
                                      checked={permission.actions.includes(
                                        "module"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "module"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Module`}
                                    >
                                      Module
                                    </label>
                                  </td>
                                  <td
                                    key="Announcements"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Read`}
                                      id={`${permission.moduleName}Read`}
                                      checked={permission.actions.includes(
                                        "read"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "read"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Read`}
                                    >
                                      Read
                                    </label>
                                  </td>
                                </>
                              )}
                              {permission.moduleName === "Complaints" && (
                                <>
                                  <td
                                    key="Complaints"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Module`}
                                      id={`${permission.moduleName}Module`}
                                      checked={permission.actions.includes(
                                        "module"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "module"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Module`}
                                    >
                                      Module
                                    </label>
                                  </td>
                                  <td
                                    key="Complaints"
                                    className="td_permission"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}Read`}
                                      id={`${permission.moduleName}Read`}
                                      checked={permission.actions.includes(
                                        "read"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          "read"
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}Read`}
                                    >
                                      Read
                                    </label>
                                  </td>
                                </>
                              )}
                            </>
                          ) : roleType === 4 &&
                            societyLevelRole === "societyLevel" ? (
                            // Filter out "edit" for specific modules only
                            defaultActions
                              .filter((action) => {
                                // Hide "edit" for specific modules only
                                if (
                                  (permission.moduleName ===
                                    "Type of Entries" ||
                                    permission.moduleName ===
                                      "Purpose of Occasional") &&
                                  action.toLowerCase() === "edit"
                                ) {
                                  return false;
                                }
                                return true;
                              })
                              .map((action) => (
                                <td key={action} className="td_permission">
                                  <input
                                    type="checkbox"
                                    name={`${permission.moduleName}${action}`}
                                    id={`${permission.moduleName}${action}`}
                                    checked={permission.actions.includes(
                                      action.toLowerCase()
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        permission.moduleName,
                                        action.toLowerCase()
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`${permission.moduleName}${action}`}
                                  >
                                    {action}
                                  </label>
                                </td>
                              ))
                          ) : (
                            defaultActions.map((action) => (
                              <td key={action}>
                                <input
                                  type="checkbox"
                                  name={`${permission.moduleName}${action}`}
                                  id={`${permission.moduleName}${action}`}
                                  checked={permission.actions.includes(
                                    action.toLowerCase()
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      permission.moduleName,
                                      action.toLowerCase()
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`${permission.moduleName}${action}`}
                                >
                                  {action}
                                </label>
                              </td>
                            ))
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="role_submit_btn">
              <button
                className="edit-button  edit_btn_create_role"
                type="submit"
              >
                Update Role
              </button>
            </div>
          </form>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default EditRole;

// import React, { useContext, useEffect, useState } from "react";
// import { useFormik } from "formik";
// import axios from "axios";
// import "./style.css";
// import { useNavigate, useParams } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import { PORT } from "../../../../Api/api";
// import Layout from "../../../../lib/Layout";
// import AddBackbtn from "../../../../lib/AddBackbtn";
// import { LanguageContext } from "../../../../lib/LanguageContext";
// const EditSubscription = () => {
//   const { language } = useContext(LanguageContext);
//   const getRoleId = JSON.parse(localStorage.getItem("roleId"));
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     name: "",
//     createdBy: getRoleId,
//     price: "",
//     societyHouseList: "",
//     description: "",
//     interval: "",
//     commissionCurrency: "",
//   });

//   const params = useParams();
//   const id = params.id;
//   const fetchSubscription = async () => {
//     try {
//       const response = await axios.get(`${PORT}getSubscriptionWithId/${id}`);
//       setFormData({
//         name: response.data.data.name || "",
//         price: response.data.data.price || "",
//         societyHouseList: response.data.data.societyHouseList || "",
//         description: response.data.data.description || "",
//         interval: response.data.data.interval || "",
//         commissionCurrency: response.data.data.commissionCurrency || "",
//       });
//     } catch (error) {}
//   };
//   useEffect(() => {
//     fetchSubscription();
//   }, []);
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`${PORT}editSubscription/${id}`, formData);
//       toast.success("Subscription updated successfully");

//       setTimeout(() => {
//         navigate("/admin/viewSubscription")
//       }, 2000);
//     } catch (error) {
//       toast.error("Error updating subscription");
//     }
//   };
//   return (
//     <>
//       <Layout>
//         <div className="table_heading">
//           <h5 className="heading_top">
//             {language === "hindi"
//               ? "Edit Subscription "
//               : "सदस्यता संपादित करें"}
//           </h5>
//           <div className="hrline"></div>
//         </div>
//         <AddBackbtn />
//         <div className="subscription-main-div">
//           <form onSubmit={handleSubmit} className="subscription-form">
//             <div>
//               <label htmlFor="name" className="editLabel">
//                 {language === "hindi" ? "Plan Name" : " योजना का नाम"}
//                 <span className="Star_color">*</span>
//               </label>
//               <br />
//               <select
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={formData.handleChange}
//                 onBlur={formData.handleBlur}
//                 className="edit-input"
//                 required
//               >
//                 <option value="" selected>
//                   {language === "hindi" ? "Select Plan" : "योजना चुनें"}{" "}
//                   {/* Placeholder option */}
//                 </option>
//                 <option value="Basic">
//                   {language === "hindi" ? "Basic" : " बेसिक"}
//                 </option>
//                 <option value="Premium">
//                   {language === "hindi" ? "Premium" : " प्रीमियम"}
//                 </option>
//                 <option value="Advanced">
//                   {language === "hindi" ? "Advanced" : " एडवांस"}
//                 </option>
//               </select>
//             </div>
//             <div>
//               <label htmlFor="price" className="editLabel">
//                 {language === "hindi" ? " Price(₹)" : " मूल्य(₹)"}
//                 <span className="Star_color">*</span>
//               </label>
//               <br />
//               <input
//                 type="number" // Change to number for Price input
//                 id="price"
//                 name="price"
//                 value={formData.price}
//                 onChange={formData.handleChange}
//                 onBlur={formData.handleBlur}
//                 placeholder={language === "hindi" ? "Enter price" : " मूल्य"}
//                 className="edit-input"
//                 min="0" // Ensure no negative values
//                 required
//               />
//             </div>

//             <div>
//               <label className="editLabel" htmlFor="societyHouseList">
//                 {language === "hindi"
//                   ? "No. of Houses in Society"
//                   : "सोसायटी में घरों की संख्या"}
//               </label>
//               <br />
//               <select
//                 id="societyHouseList"
//                 name="societyHouseList"
//                 value={formData.societyHouseList}
//                 onChange={formData.handleChange}
//                 onBlur={formData.handleBlur}
//                 className="edit-input"
//                 required
//                 // onChange={(e) => handleHouseChange(e, setFieldValue)}
//               >
//                 <option value="">Select House Range</option>
//                 <option value="0-100">0 - 100</option>
//                 <option value="200-500">200 - 500</option>
//                 <option value="500-1000">500 - 1000</option>
//               </select>
//             </div>
//             <div>
//               <label htmlFor="interval" className="editLabel">
//                 {language === "hindi" ? "Interval" : "अंतराल"}
//                 <span className="Star_color">*</span>
//               </label>
//               <br />
//               <select
//                 id="interval"
//                 name="interval"
//                 value={formData.interval}
//                 onChange={formData.handleChange}
//                 onBlur={formData.handleBlur}
//                 className="edit-input"
//                 required
//               >
//                 <option value="" selected>
//                   {language === "hindi" ? "Select Interval" : "चयनित अंतराल"}{" "}
//                   {/* Placeholder option */}
//                 </option>
//                 <option value="monthly">
//                   {language === "hindi" ? "Monthly" : " मासिक"}
//                 </option>
//                 <option value="annual">
//                   {language === "hindi" ? "Annual" : " वार्षिक"}
//                 </option>
//                 <option value="quarterly">
//                   {language === "hindi" ? "Quarterly" : " त्रैमासिक"}
//                 </option>
//               </select>
//             </div>

//             <div>
//               <label htmlFor="description" className="editLabel">
//                 {language === "hindi" ? "Description" : "विवरण"}
//                 <span className="Star_color">*</span>
//               </label>
//               <br />
//               <textarea
//                 id="description"
//                 name="description"
//                 value={formData.description}
//                 onChange={formData.handleChange}
//                 onBlur={formData.handleBlur}
//                 placeholder={
//                   language === "hindi" ? "Enter plan description" : " विवरण"
//                 }
//                 className="edit-input"
//                 maxLength="200" // Allow more characters for description
//                 required
//               />
//             </div>

//             <div>
//               <label htmlFor="commissionCurrency" className="editLabel">
//                 {language === "hindi" ? "Commission Currency" : "कमीशन मुद्रा"}
//                 <span className="Star_color">*</span>
//               </label>
//               <br />
//               <select
//                 id="commissionCurrency"
//                 name="commissionCurrency"
//                 value={formData.commissionCurrency}
//                 onChange={formData.handleChange}
//                 onBlur={formData.handleBlur}
//                 className="edit-input"
//                 required
//               >
//                 <option value="" selected>
//                   {language === "hindi"
//                     ? "Select Commission Currency "
//                     : "कमीशन मुद्रा"}{" "}
//                   {/* Placeholder option */}
//                 </option>
//                 <option value="INR">
//                   {language === "hindi" ? " ₹ (INR)" : "₹ (रुपया)"}
//                 </option>
//                 <option value="USD">
//                   {language === "hindi" ? " $ (USD)" : "$ (डॉलर)"}
//                 </option>
//                 {/* You can add more currencies if needed */}
//               </select>
//             </div>

//             <div className="role_submit_btn">
//               <button
//                 className="edit-button edit_btn_create_role"
//                 type="submit"
//               >
//                 {language === "hindi"
//                   ? "Create Subscription Plan"
//                   : "Create Subscription Plan"}
//               </button>
//             </div>
//           </form>
//         </div>
//       </Layout>
//       <ToastContainer />
//     </>
//   );
// };

// export default EditSubscription;
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PORT } from "../../../../Api/api";
import Layout from "../../../../lib/Layout";
import AddBackbtn from "../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../lib/LanguageContext";
import "./style.css";
const EditSubscription = () => {
  const { language } = useContext(LanguageContext);
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [formData, setFormData] = useState({
    name: "",
    createdBy: getRoleId,
    price: "",
    societyHouseList: "",
    description: "",
    interval: "",
    commissionCurrency: "",
  });
  // Fetch subscription data by ID
  const fetchSubscription = async () => {
    try {
      const response = await axios.get(`${PORT}getSubscriptionWithId/${id}`);
      const data = response.data.data;
      setFormData({
        name: data.name || "",
        price: data.price || "",
        societyHouseList: data.societyHouseList || "",
        description: data.description || "",
        interval: data.interval || "",
        commissionCurrency: data.commissionCurrency || "",
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to load subscription data.");
    }
  };
  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${PORT}editSubscription/${id}`, formData);
      toast.success("Subscription updated successfully");
      setTimeout(() => {
        navigate("/admin/viewSubscription");
      }, 2000);
    } catch (error) {
      toast.error("Error updating subscription");
    }
  };
//function lock
useEffect(() => {
  fetchSubscription();
},[]);
  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi"
              ? "Edit Subscription"
              : "सदस्यता संपादित करें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="subscription-main-div">
          <form onSubmit={handleSubmit} className="subscription-form">
            <div>
              <label htmlFor="name" className="editLabel">
                {language === "hindi" ? "Plan Name" : "योजना का नाम"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">
                  {language === "hindi" ? "Select Plan" : "योजना चुनें"}
                </option>
                <option value="Standard">
                  {language === "hindi" ? " Standard Plan" : "स्टैंडर्ड योजना "}
                </option>
                <option value="Elite">
                  {language === "hindi" ? "Elite Plan" : "एलीट योजना"}
                </option>
                <option value="Supreme">
                  {language === "hindi" ? "Supreme Plan" : " सुप्रीम योजना"}
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="price" className="editLabel">
                {language === "hindi"
                  ? " Price Per household(₹)"
                  : " प्रत्येक घर का मूल्य(₹)"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                placeholder={language === "hindi" ? "Enter price" : "मूल्य"}
                className="edit-input"
                min="0"
                required
              />
            </div>

            <div>
              <label className="editLabel" htmlFor="societyHouseList">
                {language === "hindi"
                  ? "No. of Houses in Society"
                  : "सोसायटी में घरों की संख्या"}
              </label>
              <br />
              <select
                id="societyHouseList"
                name="societyHouseList"
                value={formData.societyHouseList}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">Select House Range</option>
                <option value="0-250">0 - 250</option>
                <option value="250-500">250 - 500</option>
                <option value="500-1000">500 - 1000</option>
              </select>
            </div>

            <div>
              <label htmlFor="interval" className="editLabel">
                {language === "hindi" ? "Interval" : "अंतराल"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="interval"
                name="interval"
                value={formData.interval}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">Select Interval</option>
                <option value="monthly">
                  {language === "hindi" ? "Monthly" : "मासिक"}
                </option>
                <option value="annual" disabled={true}>
                  {language === "hindi" ? "Annual" : "वार्षिक"}
                </option>
                <option value="quarterly" disabled={true}>
                  {language === "hindi" ? "Quarterly" : "त्रैमासिक"}
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="description" className="editLabel">
                {language === "hindi" ? "Description" : "विवरण"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder={
                  language === "hindi" ? "Enter plan description" : "विवरण"
                }
                className="edit-input"
                maxLength="200"
                required
              />
            </div>

            <div>
              <label htmlFor="commissionCurrency" className="editLabel">
                {language === "hindi" ? "Commission Currency" : "कमीशन मुद्रा"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="commissionCurrency"
                name="commissionCurrency"
                value={formData.commissionCurrency}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">Select Commission Currency</option>
                <option value="INR">₹ (INR)</option>
                <option value="USD" disabled={true}>
                  $ (USD)
                </option>
              </select>
            </div>

            <div className="role_submit_btn">
              <button
                className="edit-button edit_btn_create_role"
                type="submit"
              >
                {language === "hindi"
                  ? "Update Subscription Plan"
                  : "Update Subscription Plan"}
              </button>
            </div>
          </form>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default EditSubscription;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { PermissionContext } from "../../../../lib/PermissionContext";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { ThreeCircles } from "react-loader-spinner";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import "./style.css";
import { formatDate } from "../../../../lib/FormattedDate";
import { FaEdit } from "react-icons/fa";
import { FaCheckCircle, FaDollarSign } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { MdOutlineCreditScore } from "react-icons/md";
import { MdOutlineCreditCardOff } from "react-icons/md";
import Swal from "sweetalert2";
import { FaLevelDownAlt } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

import ModalViewBillingDetails from "../modalViewBillingDetails/ModalViewBillingDetails";
const SuperAdminViewBillingDetails = () => {
  const role_level = JSON.parse(localStorage.getItem("role")) || null;
  const { permissions } = useContext(PermissionContext);
  const { language } = useContext(LanguageContext);
  const [societyData, setSocietyData] = useState([]);
  const [societyFilterData, setSocietyFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSocietyId, setSelectedSocietyId] = useState(null);
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [billsData, setBillsData] = useState([]);
  const [showAllBills, setShowAllBills] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  //fetch society biils
  const fetchSocietyData = async () => {
    try {
      const response = await axios.get(`${PORT}/getAllBills`);
      const res = await response.data;
      setSocietyData(res.reverse());
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);
    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  // Handle selection changes
  const handleSocietyChange = (event, value) => {
    if (value) {
      setSelectedSociety(value); // Update selected value
      setSelectedSocietyId(value.society_id._id); // Update selected ID
      const data = societyData.filter(
        (item) => item.society_id._id === value.society_id._id
      );
      setSocietyFilterData(data[0]); // Update filtered data
    } else {
      // If input is cleared, reset to default (first entry)
      if (societyData && societyData.length > 0) {
        const firstSociety = societyData[0];
        setSelectedSociety(firstSociety); // Reset to default
        setSelectedSocietyId(firstSociety.society_id._id);
        setSocietyFilterData(firstSociety);
      }
    }
  };
  //fetchSocietyBillsDetails
  const fetchSocietyBillsDetails = async () => {
    try {
      const response = await axios.get(`${PORT}/getAllBills`);
      const res = await response.data;
      // Extracting all bills from each billing object
      const allBills = res.flatMap((billing) => billing.bills);
      setBillsData(allBills);
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };
  //handleAllFilter
  const handleAllFilter = () => {
    setShowAllBills(true);
  };
  //handleClearAllFilter
  const handleClearAllFilter = () => {
    setShowAllBills(false);
    setSelectedMonth(""); // Reset the selected month
  };
  //handle payment status
  const handlePaymentStatus = async (society_id, billId, paymentStatus) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      const data = {
        paymentStatus: paymentStatus,
      };
      const res = await axios.put(
        `${PORT}/updatePaymentStatus/${society_id}/${billId}`,
        data
      );

      Swal.fire({
        title: "Updated!",
        text: `${res.data.message}`,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      fetchSocietyBillsDetails();
      fetchSocietyData();
    } catch (error) {
      console.log(error);
    }
  };
  //month dropdown filter

  //month logic
  const generateMonthOptions = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const currentYear = new Date().getFullYear();
    return months.map((month, index) => ({
      name: `${month} ${currentYear}`, // Format as "Jan 2024"
      value: `${month} ${currentYear}`, // Store as value too
    }));
  };

  const options = generateMonthOptions();
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  };

  // Function to filter bills based on the selected month
  // Filter bills based on the selected month
  const filteredBills = useMemo(() => {
    if (!selectedMonth) return billsData;
    const [month, year] = selectedMonth.split(" ");
    const selectedDate = new Date(
      `${year}-${
        month === "Jan"
          ? 1
          : month === "Feb"
          ? 2
          : month === "Mar"
          ? 3
          : month === "Apr"
          ? 4
          : month === "May"
          ? 5
          : month === "Jun"
          ? 6
          : month === "Jul"
          ? 7
          : month === "Aug"
          ? 8
          : month === "Sep"
          ? 9
          : month === "Oct"
          ? 10
          : month === "Nov"
          ? 11
          : 12
      }-01`
    );

    return billsData.filter((bill) => {
      const billDate = parseDate(bill.submitedDate);
      return (
        billDate.getMonth() === selectedDate.getMonth() &&
        billDate.getFullYear() === selectedDate.getFullYear()
      );
    });
  }, [selectedMonth, billsData]);
  //extract date
  //formated Date in Day
  function extractDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  //data range filter
  const filteredData = useMemo(() => {
    let data = societyFilterData?.bills;
    // Date range filter
    if (startDate) {
      data = data.filter((item) => {
        const startBillingDate = extractDate(item.billing_period_startDate);
        const formattedDate = new Date(startBillingDate);
        return formattedDate >= new Date(startDate);
      });
    }

    if (endDate) {
      data = data.filter((item) => {
        const endBillingDate = extractDate(item.billing_period_endDate);
        const formattedDate = new Date(endBillingDate);
        return formattedDate <= new Date(endDate);
      });
    }
    return data;
  }, [searchTerm, startDate, endDate]);
  //function lock
  useEffect(() => {
    fetchSocietyData();
    fetchSocietyBillsDetails();
  }, []);
  useEffect(() => {
    const onMouseEnter = () => {
      setIsOpen(true);
    };

    const onMouseLeave = () => {
      setIsOpen(false);
    };
  }, []);
  // Automatically pick the first entry
  useEffect(() => {
    if (societyData && societyData.length > 0) {
      const firstSociety = societyData[0];
      setSelectedSociety(firstSociety); // Set the first option as default
      setSelectedSocietyId(firstSociety.society_id._id); // Set its ID
      setSocietyFilterData(firstSociety); // Set filtered data to the first entry
      setLoading(false); 
    }
  }, [societyData]);

  return (
    <div className="card-body px-0 pt-0 pb-2 w-100 ">
      <div className="table-responsive p-0 bg-black">
        <div className="top-heading-billing-dropdown-allFilter">
          <div>
            <Autocomplete
              id="country-select-demo"
              sx={{
                width: 300,
                margin: "10px",
                "&.Mui-disabled": {
                  backgroundColor: "#f5f5f5", // Set background color for the disabled state
                  cursor: "not-allowed", // Change the cursor to indicate it's disabled
                  opacity: 0.6, // Make it less opaque when disabled
                },
              }}
              options={societyData}
              value={selectedSociety}
              autoHighlight
              getOptionLabel={(option) =>
                option.society_id.label || option.society_id.name || "Unknown"
              }
              onChange={handleSocietyChange}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;

                // Safely handle option.code with a fallback to 'default'
                const flagCode = option.code
                  ? option.code.toLowerCase()
                  : "default";

                return (
                  <Box
                    key={key}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...optionProps}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${flagCode}.png 2x`} // Use flagCode here
                      src={`https://flagcdn.com/w20/${flagCode}.png`} // Use flagCode here
                      alt=""
                    />
                    {option.society_id.name}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a society"
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    },
                  }}
                />
              )}
              disabled={showAllBills}
            />
          </div>
          <div className="top-heading-all-filter-button">
            <button onClick={handleAllFilter}>All</button>
          </div>
          <div className="top-heading-clear-all-filter-button">
            <button onClick={handleClearAllFilter}>Clear Filter</button>
          </div>
          <div>
            <div
              className={`filterItemWrapper ${
                showAllBills === false ? "disabled" : ""
              }`}
            >
              <div
                className="filterItemButton"
                onClick={() => setIsOpen((prev) => !prev)}
              >
                <FaChevronDown
                  style={
                    isOpen
                      ? {
                          transform: "rotate(180deg)",
                          transition: "all 300ms ease-out",
                          color: "white",
                        }
                      : { color: "white" }
                  }
                />
                <span style={{ color: "white" }} className="selectedMonthLabel">
                  {selectedMonth ? (
                    selectedMonth
                  ) : (
                    <span className="select-month-font-size">Select Month</span>
                  )}
                </span>
              </div>

              {isOpen && (
                <div
                  className="filterItemDropdown"
                  style={{ minWidth: "150px" || "120px" }}
                >
                  <div className="filterItemDropdownArrow"></div>
                  <ul className="filterItemList">
                    {options?.map((option, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setSelectedMonth(option.name);
                          setIsOpen(false);
                        }}
                        className={`filterItemListItem ${
                          selectedMonth === option.name
                            ? "filterItemListItemSelected"
                            : ""
                        }`}
                      >
                        {option.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {showAllBills === true ? (
          <>
            <div className="billing-container">
              <div className="section-wrapper">
                {/* Current Plan Summary */}
                <div className="card-billing-wrapper">
                  <div className="card-billing-heading2">
                    <div className="card-billing-heading-title">
                      Billing Details{" "}
                    </div>
                  </div>
                  <div className="card-billing-body">
                    <div className="billing-container">
                      <table className="billing-table">
                        <thead>
                          <tr>
                            <th>Society Name</th>
                            <th>Address</th>
                            <th>Contact No.</th>
                            <th>Plan Type</th>
                            <th>Interval Type</th>
                            <th>Society Status</th>

                            <th>Billing Cycle</th>
                            <th>Billing Period Start Date</th>
                            <th>Billing Period End Date</th>
                            <th>Invoice No.</th>
                            <th>Total Houses</th>
                            <th>Amount</th>
                            <th>Discount Amount</th>
                            <th>Net Payval</th>
                            <th>Due Date</th>
                            <th>Payment Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {loading ? (
                          <td className="no-data-billing-td" colSpan={6}>
                            <div className="billing-loading">
                              <ThreeCircles
                                visible={true}
                                height={60}
                                width={60}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          </td>
                        ) : filteredBills?.length > 0 ? (
                          filteredBills?.map((item) => {
                            return (
                              <>
                                <tbody>
                                  <tr>
                                    <td>
                                      {" "}
                                      <strong>
                                        {item
                                          ? item.society_id?.name
                                          : "" || "No Data"}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong>
                                        {item
                                          ? item.society_id?.address
                                          : "" || "No Data"}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong>
                                        {item
                                          ? item.society_id
                                              ?.societyContactNumber
                                          : "" || "No Data"}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong>
                                        {item
                                          ? item.society_id?.planType
                                          : "" || "No Data"}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        className={
                                          item
                                            ? item.society_id?.intervalType
                                            : "" === "monthly"
                                            ? "interval-monthly"
                                            : item
                                            ? item.society_id?.intervalType
                                            : "" === "annual"
                                            ? "interval-annual"
                                            : item
                                            ? item.society_id?.intervalType
                                            : "" === "quarterly"
                                            ? "interval-quarterly"
                                            : ""
                                        }
                                      >
                                        {item
                                          ? item.society_id?.intervalType.toUpperCase()
                                          : "" || "No Data"}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          color:
                                            item.society_id?.isActive === true
                                              ? "green"
                                              : item.society_id?.isActive ===
                                                false
                                              ? "red"
                                              : "orange",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item
                                          ? item.society_id?.isActive
                                            ? "Active"
                                            : "Inactive"
                                          : ""}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        className={
                                          item.billingCycle === "monthly"
                                            ? "interval-monthly"
                                            : item.billingCycle === "annual"
                                            ? "interval-annual"
                                            : item.billingCycle === "quarterly"
                                            ? "interval-quarterly"
                                            : ""
                                        }
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : (
                                          item.billingCycle.toUpperCase() ||
                                          "monthly"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong
                                        style={{
                                          // backgroundColor:
                                          //   bgColorBillingPeriodStartDate,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000",
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : (
                                          formatDateToCustom(
                                            item.billing_period_startDate
                                          )
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong
                                        style={{
                                          // backgroundColor:
                                          //   bgColorBillingPeriodStartDate,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : (
                                          formatDateToCustom(
                                            item.billing_period_endDate
                                          )
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorInvoiceNo,
                                          borderRadius: "5px",
                                          padding: "5px 10px",

                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.invoiceNo ? (
                                          item.invoiceNo
                                        ) : (
                                          "NO Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorTotalHouse,
                                          borderRadius: "5px",
                                          padding: "5px 10px",
                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.totalHouse ? (
                                          item.totalHouse
                                        ) : (
                                          "No Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <Tooltip
                                        placement="top"
                                        title={
                                          <div>
                                            Total Houses: {item.totalHouse}
                                            <br />
                                            Per House Cost(
                                            {item.currencyType}):{" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.pricePerHouse || "10"}
                                            <br />
                                            Bill Amount ({
                                              item.currencyType
                                            }):{" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.totalHouse *
                                              (item.pricePerHouse || 10)}
                                          </div>
                                        }
                                        arrow
                                      >
                                        <strong
                                          style={{
                                            // backgroundColor: bgColorAmount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : item.amount ? (
                                            item.amount
                                          ) : (
                                            "No Data"
                                          )}
                                        </strong>
                                      </Tooltip>
                                    </td>

                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorDiscountAmount,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000",
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.discountAmount ? (
                                          ` ${
                                            item.currencyType == "INR"
                                              ? "₹"
                                              : "$"
                                          } ${
                                            item.discountType === "fixed"
                                              ? item.discountAmount
                                              : item.pricePerHouse *
                                                item.totalHouse *
                                                (item.discountAmount / 100)
                                          }`
                                        ) : (
                                          "NA"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <Tooltip
                                        placement="top"
                                        title={
                                          <div>
                                            Total Houses = {item.totalHouse}
                                            <br />
                                            Per House Cost(
                                            {item.currencyType}) ={" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.pricePerHouse || "10"}
                                            <br />
                                            Sub Total = {item.amount}
                                            <br />
                                            Discount Amount ={" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.discountType === "fixed"
                                              ? item.discountAmount
                                              : item.pricePerHouse *
                                              item.totalHouse *
                                                (item.discountAmount / 100)}
                                            <br />
                                            Net Payval ({item.currencyType}) ={" "}
                                            {item.total}
                                          </div>
                                        }
                                        arrow
                                      >
                                        <strong
                                          style={{
                                            // backgroundColor: bgColorNetPayval,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : item.total ? (
                                            item.total
                                          ) : (
                                            "No Data"
                                          )}
                                        </strong>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorDiscountAmount,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item && item.dueDate ? (
                                          formatDate(item.dueDate)
                                        ) : (
                                          "No Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          borderRadius: "5px",
                                          padding: "5px 10px",

                                          color:
                                            item.paymentStatus === "paid"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.amount ? (
                                          item.paymentStatus.toUpperCase() ||
                                          "unPaid"
                                        ) : (
                                          "No Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="action-download-bill">
                                        <div>
                                          <Tooltip
                                            disabled={
                                              item.paymentStatus === "paid"
                                                ? "disabledPaymentButton"
                                                : ""
                                            }
                                            onClick={(e) => {
                                              // Allow the onClick to proceed only if the paymentStatus is not "paid"
                                              if (
                                                item.paymentStatus !== "paid"
                                              ) {
                                                handlePaymentStatus(
                                                  item?.society_id?._id ||
                                                    "null",
                                                  item._id,
                                                  "paid"
                                                );
                                              }
                                              e.preventDefault(); // Prevents the click event from triggering for disabled state
                                            }}
                                            placement="top"
                                            title={
                                              item.paymentStatus === "paid"
                                                ? language === "hindi"
                                                  ? "Paid"
                                                  : "भुगतान किया"
                                                : language === "hindi"
                                                ? "Update Payment Status"
                                                : "भुगतान स्थिति अपडेट करें"
                                            }
                                            arrow
                                          >
                                            <span
                                              className={`${
                                                item.paymentStatus === "paid"
                                                  ? "disabledPaymentButton"
                                                  : "action-to-updated-payment-status"
                                              }`}
                                            >
                                              <MdOutlineCreditScore />
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <div>
                                          <Tooltip
                                            disabled={
                                              item.paymentStatus === "paid"
                                                ? "disabledPaymentButton"
                                                : ""
                                            }
                                            onClick={(e) => {
                                              // Allow the onClick to proceed only if the paymentStatus is not "paid"
                                              if (
                                                item.paymentStatus == "paid"
                                              ) {
                                                handlePaymentStatus(
                                                  item?.society_id?._id ||
                                                    "null",
                                                  item._id,
                                                  "unpaid"
                                                );
                                              }
                                            }}
                                            placement="top"
                                            title={
                                              item.paymentStatus === "unpaid"
                                                ? language === "hindi"
                                                  ? "Unpaid"
                                                  : "बिना भुगतान किया गया बिल"
                                                : language === "hindi"
                                                ? "Update Payment Status"
                                                : "भुगतान स्थिति अपडेट करें"
                                            }
                                            arrow
                                          >
                                            <span
                                              className={`${
                                                item.paymentStatus === "unpaid"
                                                  ? "disabledPaymentButton"
                                                  : "action-to-updated-payment-status"
                                              }`}
                                            >
                                              <MdOutlineCreditCardOff />
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <div>
                                          <ModalViewBillingDetails
                                            data={item}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>{" "}
                              </>
                            );
                          })
                        ) : (
                          <td className="no-data-billing-td" colSpan={7}>
                            No Data
                          </td>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="card-billing-progress-body">
                    <div className="card-billing-progress-details"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="billing-container">
              {/* Top Section */}
              <div className="section-wrapper">
                {/* Current Plan Summary */}
                <div className="card-billing-wrapper">
                  <div className="card-billing-heading1">
                    <div className="card-billing-heading-title">
                      Society Details
                    </div>
                    <div className="card-billing-heading-button">
                      {/* <button>Upgrade</button> */}
                    </div>
                  </div>
                  <div className="card-billing-body">
                    <div className="card-billing-body-details">
                      <p>
                        <h6>Society Name</h6>
                        <strong
                          style={{
                            // backgroundColor: bgColorPlanType,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            societyFilterData?.society_id?.name || ""
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="card-billing-body-details">
                      <p>
                        <h6>Address</h6>

                        <strong
                          style={{
                            // backgroundColor: bgColorHouseCount,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            societyFilterData?.society_id?.address || ""
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="card-billing-body-details">
                      <p>
                        <h6>Contact No.</h6>

                        <strong
                          style={{
                            // backgroundColor: bgColorHouseCount,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            societyFilterData?.society_id
                              ?.societyContactNumber || ""
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="card-billing-body-details">
                      <p>
                        <h6>Plan Type</h6>

                        <strong
                          style={{
                            // backgroundColor: bgColorHouseCount,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            societyFilterData?.planType || ""
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="card-billing-body-details">
                      <p>
                        <h6>Interval Type</h6>

                        <strong
                          style={{
                            // backgroundColor: bgColorHouseCount,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            societyFilterData?.billingCycle.toUpperCase() || ""
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="card-billing-body-details">
                      <p>
                        <h6>Society Status</h6>

                        <strong
                          style={{
                            color:
                              societyFilterData?.society_id?.isActive === true
                                ? "green"
                                : societyFilterData?.society_id?.isActive ===
                                  false
                                ? "red"
                                : "orange",
                            fontWeight: "bold",
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : societyFilterData?.society_id?.isActive ? (
                            "Active"
                          ) : (
                            "Inactive"
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="billing-container">
              <div className="section-wrapper">
                {/* Current Plan Summary */}
                <div className="card-billing-wrapper">
                  <div className="card-billing-heading2">
                    <div className="card-billing-heading-title">
                      Billing Details{" "}
                    </div>
                    <div className="card-billing-heading-title">
                      {/* <div className="date-filter">
                        <TextField
                          id="start-date"
                          label={
                            language === "hindi"
                              ? "Start Date"
                              : "आरंभ करने की तिथि"
                          }
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: " white" },
                          }}
                          InputProps={{
                            style: { color: " white" },
                          }}
                          variant="outlined"
                        />
                        <TextField
                          id="end-date"
                          label={
                            language === "hindi" ? "End Date" : "अंतिम तिथि"
                          }
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "white" },
                          }}
                          InputProps={{
                            style: { color: "white" },
                          }}
                          variant="outlined"
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="card-billing-body">
                    <div className="billing-container">
                      <table className="billing-table">
                        <thead>
                          <tr>
                            <th>Billing Cycle</th>
                            <th>Billing Period Start Date</th>
                            <th>Billing Period End Date</th>
                            <th>Invoice No.</th>
                            <th>Total Houses</th>
                            <th>Amount</th>
                            <th>Discount Amount</th>
                            <th>Net Payval</th>
                            <th>Due Date</th>
                            <th>Payment Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {loading ? (
                          <td className="no-data-billing-td" colSpan={6}>
                            <div className="billing-loading">
                              <ThreeCircles
                                visible={true}
                                height={60}
                                width={60}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          </td>
                        ) : societyFilterData?.bills?.length > 0 ? (
                          societyFilterData?.bills?.map((item) => {
                            return (
                              <>
                                <tbody>
                                  <tr>
                                    <td>
                                      {" "}
                                      <strong
                                        className={
                                          item.billingCycle === "monthly"
                                            ? "interval-monthly"
                                            : item.billingCycle === "annual"
                                            ? "interval-annual"
                                            : item.billingCycle === "quarterly"
                                            ? "interval-quarterly"
                                            : ""
                                        }
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : (
                                          item.billingCycle.toUpperCase() ||
                                          "monthly"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong
                                        style={{
                                          // backgroundColor:
                                          //   bgColorBillingPeriodStartDate,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000",
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : (
                                          formatDateToCustom(
                                            item.billing_period_startDate
                                          )
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong
                                        style={{
                                          // backgroundColor:
                                          //   bgColorBillingPeriodStartDate,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : (
                                          formatDateToCustom(
                                            item.billing_period_endDate
                                          )
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorInvoiceNo,
                                          borderRadius: "5px",
                                          padding: "5px 10px",

                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.invoiceNo ? (
                                          item.invoiceNo
                                        ) : (
                                          "No Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorTotalHouse,
                                          borderRadius: "5px",
                                          padding: "5px 10px",
                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.totalHouse ? (
                                          item.totalHouse
                                        ) : (
                                          "No Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <Tooltip
                                        placement="top"
                                        title={
                                          <div>
                                            Total Houses: {item.totalHouse}
                                            <br />
                                            Per House Cost(
                                            {item.currencyType}):{" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.pricePerHouse || "10"}
                                            <br />
                                            Bill Amount ({
                                              item.currencyType
                                            }):{" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.totalHouse *
                                              (item.pricePerHouse || 10)}
                                          </div>
                                        }
                                        arrow
                                      >
                                        <strong
                                          style={{
                                            // backgroundColor: bgColorAmount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : item.amount ? (
                                            item.amount
                                          ) : (
                                            "No Data"
                                          )}
                                        </strong>
                                      </Tooltip>
                                    </td>

                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorDiscountAmount,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000",
                                        }}
                                      >
                                        {/* {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.discountAmount ? (
                                          `  ${
                                            item.currencyType == "INR"
                                              ? "₹"
                                              : "$"
                                          }${
                                            item.discountType === "fixed"
                                              ? item.discountAmount
                                              : item.amount *
                                                (item.discountAmount / 100)
                                          }
                                          
                                          `
                                        ) : (
                                          "No Data"
                                        )} */}

                                        {item.currencyType == "INR" ? "₹" : "$"}

                                        {item.discountType === "fixed"
                                          ? item.discountAmount
                                          : item.pricePerHouse *
                                            item.totalHouse *
                                            (item.discountAmount / 100)}
                                      </strong>
                                    </td>
                                    <td>
                                      <Tooltip
                                        placement="top"
                                        title={
                                          <div>
                                            Total Houses = {item.totalHouse}
                                            <br />
                                            Per House Cost(
                                            {item.currencyType}) ={" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.pricePerHouse || "10"}
                                            <br />
                                            Sub Total = {item.amount}
                                            <br />
                                            Discount Amount ={" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.discountType === "fixed"
                                              ? item.discountAmount
                                              : item.pricePerHouse *
                                                item.totalHouse *
                                                (item.discountAmount / 100)}
                                            <br />
                                            Net Payval ({item.currencyType}) ={" "}
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.total}
                                          </div>
                                        }
                                        arrow
                                      >
                                        <strong
                                          style={{
                                            // backgroundColor: bgColorNetPayval,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : item.total ? (
                                            item.total
                                          ) : (
                                            "No Data"
                                          )}
                                        </strong>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          // backgroundColor: bgColorDiscountAmount,
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          color: "#000", // Black text for contrast
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.dueDate ? (
                                          formatDate(item.dueDate)
                                        ) : (
                                          "No Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <strong
                                        style={{
                                          borderRadius: "5px",
                                          padding: "5px 10px",

                                          color:
                                            item.paymentStatus === "paid"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {loading ? (
                                          <div className="">
                                            <ThreeCircles
                                              visible={true}
                                              height={30}
                                              width={30}
                                              color="#5e72e4"
                                              ariaLabel="three-circles-loading"
                                            />
                                          </div>
                                        ) : item.amount ? (
                                          item.paymentStatus.toUpperCase() ||
                                          "unPaid"
                                        ) : (
                                          "No Data"
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="action-download-bill">
                                        <div>
                                          <Tooltip
                                            disabled={
                                              item.paymentStatus === "paid"
                                                ? "disabledPaymentButton"
                                                : ""
                                            }
                                            onClick={(e) => {
                                              // Allow the onClick to proceed only if the paymentStatus is not "paid"
                                              if (
                                                item.paymentStatus !== "paid"
                                              ) {
                                                handlePaymentStatus(
                                                  item?.society_id?._id ||
                                                    "null",
                                                  item._id,
                                                  "paid"
                                                );
                                              }
                                              e.preventDefault(); // Prevents the click event from triggering for disabled state
                                            }}
                                            placement="top"
                                            title={
                                              item.paymentStatus === "paid"
                                                ? language === "hindi"
                                                  ? "Paid"
                                                  : "भुगतान किया"
                                                : language === "hindi"
                                                ? "Update Payment Status"
                                                : "भुगतान स्थिति अपडेट करें"
                                            }
                                            arrow
                                          >
                                            <span
                                              className={`${
                                                item.paymentStatus === "paid"
                                                  ? "disabledPaymentButton"
                                                  : "action-to-updated-payment-status"
                                              }`}
                                            >
                                              <MdOutlineCreditScore />
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <div>
                                          <Tooltip
                                            disabled={
                                              item.paymentStatus === "paid"
                                                ? "disabledPaymentButton"
                                                : ""
                                            }
                                            onClick={(e) => {
                                              // Allow the onClick to proceed only if the paymentStatus is not "paid"
                                              if (
                                                item.paymentStatus == "paid"
                                              ) {
                                                handlePaymentStatus(
                                                  item?.society_id?._id ||
                                                    "null",
                                                  item._id,
                                                  "unpaid"
                                                );
                                              }
                                            }}
                                            placement="top"
                                            title={
                                              item.paymentStatus === "unpaid"
                                                ? language === "hindi"
                                                  ? "Unpaid"
                                                  : "बिना भुगतान किया गया बिल"
                                                : language === "hindi"
                                                ? "Update Payment Status"
                                                : "भुगतान स्थिति अपडेट करें"
                                            }
                                            arrow
                                          >
                                            <span
                                              className={`${
                                                item.paymentStatus === "unpaid"
                                                  ? "disabledPaymentButton"
                                                  : "action-to-updated-payment-status"
                                              }`}
                                            >
                                              <MdOutlineCreditCardOff />
                                            </span>
                                          </Tooltip>
                                        </div>
                                        {/* <div>
                                          <ModalViewBillingDetails
                                            data={
                                              item && item?.bills
                                                ? item?.bills
                                                : ""
                                            }
                                          />
                                        </div> */}
                                      </div>
                                      <div className="action-download-bill"></div>
                                    </td>
                                  </tr>
                                </tbody>{" "}
                              </>
                            );
                          })
                        ) : (
                          <td className="no-data-billing-td" colSpan={6}>
                            No Data
                          </td>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="card-billing-progress-body">
                    <div className="card-billing-progress-details"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SuperAdminViewBillingDetails;

import React, { useContext, useEffect, useState } from "react";
import { FaUser, FaAddressCard, FaImages, FaCar } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import { MdContactPhone } from "react-icons/md";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { TbPasswordUser } from "react-icons/tb";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { FaHouseUser } from "react-icons/fa";
import { TbBuildingEstate } from "react-icons/tb";
import { GrStatusUnknown } from "react-icons/gr";
import { FaRecycle } from "react-icons/fa";
import { FaHourglassStart } from "react-icons/fa6";
import { MdOutlineDoNotDisturbOnTotalSilence } from "react-icons/md";

import { FaFileInvoice } from "react-icons/fa";
import { FaHouseChimneyWindow } from "react-icons/fa6";
import { MdDiscount } from "react-icons/md";

import { TbCalendarDue } from "react-icons/tb";

import Tooltip from "@mui/material/Tooltip";
import { formatDate } from "../../../../lib/FormattedDate";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ModalViewBillingDetails = (data) => {
  const [billDetails, setBillDetails] = useState([]);
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);

    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  const [value, setValue] = React.useState(0);
  const { language } = useContext(LanguageContext);

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //function lock
  useEffect(() => {
    if (data) {
      setBillDetails(data?.data);
    } else {
      setBillDetails();
    }
  }, []);
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip
            title={language === "hindi" ? "view" : "देखना"}
            placement="top"
            arrow
          >
            <div onClick={toggleDrawer(anchor, true)}>
              <h5 className=" mt-3 house-view-icon ">
                <IoEyeSharp
                  data-toggle="tooltip"
                  className="eyes_view"
                  data-placement="top"
                />
              </h5>
            </div>
          </Tooltip>
          <Drawer
            className="regular_drawer   show-top"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              width: "100vw",
              maxWidth: "100vw",
            }}
          >
            {/* <div className="heading_user"> */}
            {/* <div> */}
            <div className="close_btn" onClick={toggleDrawer(anchor, false)}>
              <IoMdClose className="close_icons_house" />
            </div>

            <hr className="hr_line" />
            <List>
              <div className="regular-view-main-div">
                <div className="regular-images-view-title">
                  {" "}
                  {language === "hindi" ? "Society Details" : "सोसाइटी विवरण"}
                </div>
                <div className="main_content_div">
                  <div className="content1_left_div ">
                    <h3 className="house_title_left">
                      <FaHouseUser />
                      &nbsp;{" "}
                      {language === "hindi" ? "Society name " : "समाज का नाम"}
                    </h3>
                    <h3 className="house_title_left">
                      <FaAddressCard />
                      &nbsp;{language === "hindi" ? "Address" : "पता"}
                    </h3>
                    <h3 className="house_title_left">
                      <TbBuildingEstate />
                      &nbsp;{language === "hindi" ? "State" : "राज्य"}
                    </h3>
                    <h3 className="house_title_left">
                      <TbBuildingEstate />
                      &nbsp;{language === "hindi" ? "City" : "शहर"}
                    </h3>
                    <h3 className="house_title_left">
                      <FaUser />
                      &nbsp;{" "}
                      {language === "hindi"
                        ? "Society contact no. "
                        : "समाज संपर्क नंबर"}
                    </h3>

                    <h3 className="house_title_left">
                      <GrStatusUnknown />
                      &nbsp;
                      {language === "hindi"
                        ? "Society Status "
                        : " समाज स्थिति"}
                    </h3>
                  </div>
                  <div className="content1_right_div">
                    <React.Fragment>
                      <h3 className="user_images_heading">
                        {billDetails
                          ? billDetails?.society_id
                            ? billDetails?.society_id?.name
                            : ""
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails
                          ? billDetails?.society_id
                            ? billDetails?.society_id?.address
                            : ""
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails
                          ? billDetails?.society_id
                            ? billDetails?.society_id?.state
                            : ""
                          : "Not Added"}
                      </h3>

                      <h3 className="user_images_heading">
                        {billDetails
                          ? billDetails?.society_id
                            ? billDetails?.society_id?.city
                            : ""
                          : "Not Added"}
                      </h3>

                      <h3 className="user_images_heading">
                        {billDetails
                          ? billDetails?.society_id
                            ? billDetails?.society_id?.societyContactNumber
                            : ""
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails
                          ? billDetails?.society_id
                            ? billDetails?.society_id?.isActive === true
                              ? "ACTIVE"
                              : "INACTIVE"
                            : ""
                          : "Not Added"}
                      </h3>
                    </React.Fragment>
                  </div>
                </div>
                <br />
                <div className="regular-images-view-title">
                  {" "}
                  {language === "hindi" ? "Billing Details" : "बिलिंग विवरण"}
                </div>
                <div className="main_content_div">
                  <div className="content1_left_div ">
                    <h3 className="house_title_left">
                      <FaRecycle />
                      &nbsp;
                      {language === "hindi"
                        ? " Billing cycle  "
                        : "बिलिंग चक्र"}
                    </h3>
                    <h3 className="house_title_left">
                      <FaHourglassStart />
                      &nbsp;
                      {language === "hindi"
                        ? "Billing period startDate"
                        : "बिलिंग अवधि प्रारंभ तिथि"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <FaHourglassStart />
                      &nbsp;
                      {language === "hindi"
                        ? "Billing period endDate "
                        : "बिलिंग अवधि समाप्त तिथि"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <FaFileInvoice />
                      &nbsp;
                      {language === "hindi"
                        ? "Invoice no. "
                        : "चालान संख्या"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <FaHouseChimneyWindow />
                      &nbsp;
                      {language === "hindi" ? "Total Houses " : "कुल घर"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <FaHouseChimneyWindow />
                      &nbsp;
                      {language === "hindi"
                        ? "Per house hold cost "
                        : "प्रति गृह लागत"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <MdDiscount />
                      &nbsp;
                      {language === "hindi"
                        ? "Discount Amount"
                        : "छूट राशि"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <TbCalendarDue />
                      &nbsp;
                      {language === "hindi" ? "Due Date" : "निपटान तिथि?"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <MdOutlineDoNotDisturbOnTotalSilence />
                      &nbsp;
                      {language === "hindi" ? "Total" : "कुल"}{" "}
                    </h3>
                    <h3 className="house_title_left">
                      <GrStatusUnknown />
                      &nbsp;
                      {language === "hindi"
                        ? "Payment Status"
                        : "भुगतान स्थिति"}{" "}
                    </h3>
                  </div>
                  <div className="content1_right_div">
                    <React.Fragment>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.billingCycle
                          ? billDetails.billingCycle.toUpperCase()
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.billing_period_startDate
                          ? formatDateToCustom(
                              billDetails.billing_period_startDate
                            )
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.billing_period_endDate
                          ? formatDateToCustom(
                              billDetails.billing_period_endDate
                            )
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.invoiceNo
                          ? billDetails.invoiceNo
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.totalHouse
                          ? billDetails.totalHouse
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.pricePerHouse
                          ? `${
                              billDetails.currencyType === "INR" ? "₹" : " $"
                            }${billDetails.pricePerHouse}`
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.discountAmount
                          ? billDetails.discountAmount
                          : "NA"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.dueDate
                          ? formatDate(billDetails.dueDate)
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {billDetails && billDetails.total
                          ? billDetails.total
                          : "Not Added"}
                      </h3>
                      <h3
                        className="user_images_heading"
                        style={{
                          color:
                            billDetails && billDetails.paymentStatus === "paid"
                              ? "green"
                              : "red",
                        }}
                      >
                        {billDetails && billDetails.paymentStatus
                          ? billDetails.paymentStatus.toUpperCase()
                          : "Not Added"}
                      </h3>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ModalViewBillingDetails;

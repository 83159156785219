import Swal from "sweetalert2";
const swalAlert = () => {
  return Swal.fire({
    position: "center",
    icon: "info",
    title: "No Data Found",
    showConfirmButton: true,
    timer: 1500,
  });
};
export default swalAlert;
